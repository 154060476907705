<template>
  <div class="location-wrap">
    <p class="location-city-title">定位城市</p>

    <div class="location-city" @click="goHome">
      <div class="location-item">
        <img src="../assets/image/location.png" alt="" />
        <span>{{ locationCity }}</span>
      </div>
    </div>

    <div class="hot-city-title">热门城市</div>

    <div class="city-list">
      <span
        v-for="item in cityList"
        :key="item.cityId"
        @click="
          () => {
            selectCity(item);
          }
        "
        >{{ item.cityName }}</span
      >
    </div>
  </div>
</template>

<script>
import { $http_app } from "../utils/request";

export default {
  created() {
    $http_app({
      url: "/appapi/home/switchweb",
      method: "get",
    }).then((res) => {
      this.cityList = res.data.filter(item => item.name !== '东莞').map((item) => {
        return { cityName: item.name, cityId: item.id };
      });
    });
  },
  mounted() {
    document.getElementsByTagName("body")[0].style.background = "#fff";
  },
  data() {
    return {
      cityList: [],
    };
  },
  computed: {
    locationCity() {
      return (
        (this.$store.state.location && this.$store.state.location.cityName) ||
        "北京"
      );
    },
  },
  methods: {
    handleReset() {
      // TODO 定位重置
    },
    selectCity(cityItem) {
      // 根据不同的城市，取得对应的城市贷款计算方式
      this.$store.dispatch("setLocation", cityItem);
      this.$router.push('/')
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.location-wrap {
  background-color: #ffffff;
  height: 100%;
}
.location-wrap img {
  width: 44px;
  height: 44px;
}
.location-city-title,
.hot-city-title {
  box-sizing: border-box;
  padding: 0 32px;
  height: 100px;
  line-height: 100px;
  color: #999999;
  font-size: 30px;
  font-weight: 500;
  background: #f4f4f4;
}
.location-city {
  padding: 0 32px;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.location-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.city-list {
  background-color: #ffffff;
  padding: 45px 45px 0 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.city-list > span {
  display: inline-block;
  width: 135px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  background: #f5f5f5;
  color: #333;
  font-size: 28px;
  margin-bottom: 40px;
}
</style>